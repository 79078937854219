import type { SpinProps } from 'antd'

import Spin from '../spin'

export default function GlobalSpin(props: SpinProps): React.ReactElement {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-[rgba(255,255,255,0.6)] z-[999999] h-screen backdrop-blur-md -webkit-backdrop-filter saturate-180  translate-z-0">
      <Spin {...props} />
    </div>
  )
}
