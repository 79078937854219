import { Button as AButton, ConfigProvider } from 'antd'
import type { ComponentToken } from 'antd/es/button/style'
import type { AliasToken } from 'antd/es/theme/internal'
import type { MappingAlgorithm } from 'antd/lib/theme/interface'
import { isFunction } from 'lodash'
import type { CSSProperties } from 'react'

export interface IGCButtonProps {
  type?: 'primary' | 'default' | 'dashed' | 'link' | 'text'
  children: React.ReactElement | string
  width?: number
  height?: number
  // convert button to hollow with border
  ghost?: boolean
  disabled?: boolean
  fontSize?: number
  htmlType?: 'button' | 'reset' | 'submit'
  className?: string
  onClick?: () => void
  style?: CSSProperties
  icon?: React.ReactNode
  id?: string
  block?: boolean
  href?: string
  buttonConfigProvider?: Partial<ComponentToken> &
    Partial<AliasToken> & {
      algorithm?: boolean | MappingAlgorithm | MappingAlgorithm[]
    }
}

export default function Button(props: IGCButtonProps): React.ReactElement {
  const {
    type = 'default',
    children,
    width,
    height = 52,
    ghost = false,
    disabled = false,
    htmlType = 'button',
    fontSize = 14,
    style = {},
    onClick,
    className = '',
    icon,
    id,
    block = false,
    href,
    buttonConfigProvider
  } = props

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            ...buttonConfigProvider
          }
        }
      }}
      wave={{ disabled: true }}
    >
      <AButton
        type={type}
        disabled={disabled}
        id={id}
        ghost={ghost}
        icon={icon}
        block={block}
        href={href}
        onClick={() => {
          if (onClick && isFunction(onClick)) {
            onClick()
          }
        }}
        htmlType={htmlType}
        style={{
          width: width !== undefined ? `${width}px` : '100%',
          height: `${height}px`,
          fontSize: `${fontSize}px`,
          ...style
        }}
        className={className}
      >
        {children}
      </AButton>
      <style jsx global>{`
        body
          .ant-btn-primary.ant-btn-background-ghost:not(:disabled):not(
            .ant-btn-disabled
          ):hover {
          color: white;
          border-color: var(--ghostColor);
          background: var(--ghostColor);
        }
        .fluid-width-enabled
          .ant-btn-color-primary:not(:hover):not(:active):not(:focus):not(
            :disabled
          ) {
          background: var(--primaryColor);
        }
      `}</style>
    </ConfigProvider>
  )
}
