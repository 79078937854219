import type {
  FormInstance as FormInstanceType,
  FormItemProps as FormItemPropsType,
  MappingAlgorithm
} from 'antd'
import { ConfigProvider, Form as AForm } from 'antd'
import type { ComponentToken } from 'antd/es/form/style'
import type { AliasToken } from 'antd/es/theme/internal'
import type { Rule as ARule } from 'antd/lib/form'
import { createContext, useMemo } from 'react'

export default AForm
export type Rule = ARule

interface IFormItemContextProps {
  name?: string
}

interface IFormItemProps extends FormItemPropsType {
  formItemConfigProvider?: Partial<ComponentToken> &
    Partial<AliasToken> & {
      algorithm?: boolean | MappingAlgorithm | MappingAlgorithm[]
    }
}

export const FormItemContext = createContext<IFormItemContextProps | null>(null)

export function FormItem(props: IFormItemProps): React.ReactElement {
  const { formItemConfigProvider, ...restProps } = props
  return (
    <FormItemContext.Provider
      value={useMemo(() => ({ name: props.name as string }), [props.name])}
    >
      <ConfigProvider
        theme={{
          components: {
            Form: {
              ...formItemConfigProvider
            }
          }
        }}
      >
        <AForm.Item {...restProps} />
      </ConfigProvider>
      <style jsx global>{`
        .ant-form-item.ant-form-item-has-feedback {
          & span.ant-select-arrow,
          & span.ant-input-suffix .ant-form-item-feedback-icon-error {
            display: none;
          }
        }
        .ant-input-password.isActive .ant-input-password-icon {
          position: relative;
          top: -11px;
        }
        .ant-form-item-has-error .ant-input-password .ant-input-password-icon {
          position: relative;
          right: -5px;
        }
        .ant-form-item-has-error .ant-form-item-control-input .input-wrapper {
          border-color: var(--errorColor);
        }
        .ant-form-item-has-warning .ant-form-item-control-input .input-wrapper {
          border-color: var(--warningColor);
        }
      `}</style>
    </FormItemContext.Provider>
  )
}

export const useForm = AForm.useForm
export type FormItemProps = FormItemPropsType
export type FormInstance = FormInstanceType
