import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'
import type { SpinProps } from 'antd'
import { Spin as ASpin } from 'antd'
import { omit } from 'lodash'

interface SpinExtendProps extends SpinProps {
  indicatorSize?: number
}

export default function Spin(props: SpinExtendProps): React.ReactElement {
  const { indicatorSize = 32 } = props
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: indicatorSize,
        color: 'var(--textNormalColor)',
        fontWeight: 'bold'
      }}
      spin
    />
  )

  return (
    <>
      <ASpin indicator={antIcon} {...omit(props, 'indicatorSize')} />
      {/* overwrite @primary-color  */}
      <style global jsx>
        {`
          .tailwind .ant-spin-dot-item {
            background-color: var(--textNormalColor);
          }
        `}
      </style>
    </>
  )
}
