export const DAYS = {
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THR: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
  SUN: 'Sunday'
}
export const ONE_MINUTE = 1000 * 60
export const ONE_HOUR = ONE_MINUTE * 60
export const ONE_DAY = ONE_HOUR * 24
export const ONE_WEEK = 7 * 24 * 60 * 60 * 1000
