export const initCallEmailSubmitSuccessMessages = () => {
  ;(window as any).GCI.callEmailSubmitSuccessMessages = function (formId) {
    if (!formId) {
      return
    }

    // pdp is responsive, should be the same logic no need to check mobile or desktop
    const thanksMarkup =
      '<p class="signup-thanks">Thank you for signing up!</p>'

    /**
     * @private
     * @description: shows thank you message for forms that have the default codebase markup,
     * both desktop and mobile.
     * @param {string} formId: selector of the form tag being submitted, e.g. '#formId'
     */
    const showStandardSignupThanksMessage = formId => {
      $(formId).after(thanksMarkup).remove()
    }

    /**
     * @private
     * @description: shows thank you message for the unique Daily Pick signup form,
     * both desktop and mobile.
     */
    const showDailPickSignupThanksMessage = () => {
      const $input = $('[data-js="gc-daily-pick-signup"] input[type="email"]')
      const $signUpForm = $input.closest('form')
      const $submitBtn = $signUpForm.find('input[type="submit"]')

      $input.after(thanksMarkup).remove()
      $submitBtn.remove()
      $('#clarip-consent-4').remove()
    }

    /**
     * @private
     * @description: shows thank you message for forms that have the default static page markup,
     * both desktop and mobile. This is the default case for any forms where the ID is not explicitly defined in the switch
     * @param {string} formId: selector of the form tag being submitted, e.g. '#formId'
     */
    const showStaticSignupFormThanksMessage = formId => {
      $('.thank-you').removeClass('hidden')
      $(formId).addClass('hidden')
    }

    switch (formId) {
      case '#gcNewsletterForm':
      case '#gcRiffsNewsletterForm':
      case '#mfNewsLetterForm':
      case '#mfCmsNewsletterForm':
      case '#sdotdNewsletterForm':
        showStandardSignupThanksMessage(formId)
        break
      case '#gcDailyPickSignup':
        showDailPickSignupThanksMessage()
        break
      default:
        // by default this will be called on all the static page quick sign-up forms
        showStaticSignupFormThanksMessage(formId)
        break
    }
  }
}
