import { IMAGE_PREFIX } from '@constants/media'
import { useFormatCurrency } from '@gc/common-lib/hooks/global'
import { useHeaderUserInfo } from '@gc/common-lib/hooks/header'
import { specifyImageSize } from '@gc/common-lib/utils/string'
import Image from '@gc/ui-components/image'
import Rate from '@gc/ui-components/rate'
import type { AlgoliaTypeHeadSearch } from '@typings/algolia-plp'
import { isEmpty } from 'lodash'
import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

interface RecommendProductProps {
  searchRecommendProductsData: AlgoliaTypeHeadSearch.IRecommendProducts[]
  searchQuery: string
}

export const RecommendProducts = ({
  searchRecommendProductsData,
  searchQuery
}: RecommendProductProps) => {
  const { trackEvent } = useTracking()
  const handleAnchorClick = useCallback(
    (h3TextVariant: string, itemValue: string) => {
      trackEvent({
        eventName: 'typeAheadLinkClicked',
        typeAheadSectionTitle: h3TextVariant,
        typeAheadLinkTitle: itemValue
      })
    },
    [trackEvent]
  )
  const { userLocale: { currencySymbol = '$' } = {} } = useHeaderUserInfo()
  const formatCurrency = useFormatCurrency(currencySymbol)

  if (isEmpty(searchRecommendProductsData)) {
    return null
  }

  const h3TextVariant = searchQuery
    ? 'Recommended Products'
    : 'Trending Products'

  return (
    <div className="right-products min-[1310px]:flex-1 min-[1310px]:w-0">
      <h3 className="mb-3 font-bold element-hidden legacy:block">
        {h3TextVariant}
      </h3>
      <div className="block legacy:!flex legacy:flex-wrap">
        {searchRecommendProductsData?.map(item => {
          const productUrl = item.seoUrl
            ? `${item.seoUrl}${item.seoUrl.includes('?') ? '&' : '?'}pdptypeAheadSearchInput=${searchQuery}&tNtt=${searchQuery}&tAt=item&tAv=${item.displayName}`
            : `product-detail-page/?skuId=${item?.skuId}`

          return (
            <div
              key={item?.objectID}
              className="mb-5 flex flex-col legacy:flex-row lg:odd:mr-3 items-center max-[976px]:[&:nth-child(n+3)]:!hidden"
            >
              <a
                href={productUrl}
                data-name={item.displayName}
                className="inline-block w-[90px] h-[90px] min-[1350px]:w-[104px] min-[1350px]:h-[104px] p-1 border border-textNormalColor legacy:mr-[10px] mb-1 legacy:mb-0"
                onClick={() =>
                  handleAnchorClick(h3TextVariant, item.displayName)
                }
              >
                <Image
                  src={specifyImageSize(
                    `${IMAGE_PREFIX}MMGS7/${item.imageId}-00-120x120.jpg`,
                    100
                  )}
                  alt="search production"
                  style={{ width: '100%', height: 'auto' }}
                  width={100}
                  height={100}
                />
              </a>
              <div className="production-detail w-full min-[360px]:w-[110px]">
                <a
                  className="text-[13px] legacy:text-[13px] font-semibold line-clamp-1 min-[360px]:line-clamp-3 leading-[15px] max-[975px]:text-center hover:text-textNormalColor hover:underline"
                  href={productUrl}
                  onClick={() =>
                    handleAnchorClick(h3TextVariant, item.displayName)
                  }
                >
                  {item.displayName}
                </a>
                <Rate
                  rating={item?.review?.overallRating || 0}
                  containerClassName="max-[976px]:hidden"
                />
                <div className="text-center legacy:text-left leading-[18px]">
                  {item.price ? (
                    <span className="font-bold">
                      {formatCurrency(
                        item.price?.toString()?.replace(/&nbsp;/g, ' ')
                      )}
                    </span>
                  ) : (
                    <span className="text-xs font-bold">Contact for Price</span>
                  )}
                </div>
              </div>
              <style global jsx>{`
                .right-products .mb-5 {
                  margin-bottom: 1.25rem !important;
                }
              `}</style>
            </div>
          )
        })}
      </div>
    </div>
  )
}
