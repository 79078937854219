import { default as ACheckbox } from '@gc/ui-components/checkbox'
import { ConfigProvider } from 'antd'
import type { ComponentToken } from 'antd/es/affix/style'
import type { AliasToken } from 'antd/es/theme/internal'
import type { MappingAlgorithm } from 'antd/lib/theme/interface'

import { antdThemeConfig } from '../../../antdThemeConfig'
export interface ICheckboxProps {
  children?: React.ReactElement | string
  disabled?: boolean
  defaultChecked?: boolean
  onChange?: (e) => void
  className?: string
  value?: string
  checked?: boolean
  name?: string
  checkboxConfigProvider?:
    | (Partial<ComponentToken> &
        Partial<AliasToken> & {
          algorithm?: boolean | MappingAlgorithm | MappingAlgorithm[]
        })
    | undefined
}

export function GCGhostCheckbox({
  className,
  checkboxConfigProvider,
  ...props
}: ICheckboxProps): React.ReactElement {
  return (
    <>
      <div className={`gc-ghost-checkbox inline-block ${className || ''}`}>
        <ConfigProvider
          theme={{
            components: {
              Checkbox: {
                colorBorder: antdThemeConfig.borderDarkColor,
                colorPrimary: antdThemeConfig.primaryColor,
                colorBgContainer: 'transparent',
                controlInteractiveSize: 20,
                borderRadius: antdThemeConfig.borderRadius,
                colorPrimaryHover: antdThemeConfig.primaryColor,
                colorPrimaryActive: antdThemeConfig.primaryColor,
                colorBgContainerDisabled: antdThemeConfig.borderDisabledColor,
                colorTextDisabled: antdThemeConfig.borderLessLightColor,
                ...checkboxConfigProvider
              }
            }
          }}
        >
          <ACheckbox
            {...props}
            className="flex items-start text-textNormalColor"
          />
        </ConfigProvider>
      </div>
      <style jsx global>{`
        .gc-ghost-checkbox {
          .ant-checkbox .ant-checkbox-inner {
            border-width: 2px;
          }
          .ant-checkbox .ant-checkbox-inner::after {
            top: 44%;
            width: 7px;
            height: 13px;
          }
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: transparent !important;
            border-color: var(--primaryColor) !important;
          }
          .ant-checkbox-checked .ant-checkbox-inner:after {
            border-color: var(--primaryColor);
          }
        }
        @media (min-width: 1024px) {
          .gc-ghost-checkbox
            .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
            .ant-checkbox:not(.ant-checkbox-checked)
            .ant-checkbox-inner::after {
            opacity: 1;
            border-color: var(--primaryColor);
            transform: rotate(45deg) scale(1) translate(-50%, -50%);
          }
        }
      `}</style>
    </>
  )
}

export default function GCCheckbox({
  className,
  checkboxConfigProvider,
  ...props
}: ICheckboxProps): React.ReactElement {
  return (
    <>
      <div className={`gc-checkbox inline-block ${className || ''}`}>
        <ConfigProvider
          theme={{
            components: {
              Checkbox: {
                colorBorder: antdThemeConfig.borderCheckboxColor,
                colorPrimary: antdThemeConfig.primaryColor,
                colorBgContainer: 'transparent',
                controlInteractiveSize: 22,
                borderRadius: antdThemeConfig.borderRadius,
                colorPrimaryHover: antdThemeConfig.primaryColor,
                colorPrimaryActive: antdThemeConfig.primaryColor,
                colorBgContainerDisabled: antdThemeConfig.borderDisabledColor,
                colorTextDisabled: antdThemeConfig.borderLessLightColor,
                ...checkboxConfigProvider
              }
            }
          }}
        >
          <ACheckbox
            {...props}
            className="flex items-start text-textNormalColor"
          />
        </ConfigProvider>
      </div>
      <style jsx global>{`
        .gc-checkbox {
          .ant-checkbox .ant-checkbox-inner {
            border-width: 2px;
          }
          .ant-checkbox .ant-checkbox-inner:after {
            top: 47%;
            width: 8.57142857px;
            height: 13.71428571px;
          }
        }
        @media (min-width: 1024px) {
          .gc-checkbox
            .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
            .ant-checkbox:not(.ant-checkbox-checked)
            .ant-checkbox-inner::after {
            opacity: 1;
            border-color: var(--primaryColor);
            transform: rotate(45deg) scale(1) translate(-50%, -50%);
          }
        }
      `}</style>
    </>
  )
}
