import { useTypeHeadAlgoliaSearch } from '@hooks/algolia-plp/typeHead'
import classnames from 'classnames'
import { isEmpty, trim } from 'lodash'
import { useCallback, useEffect } from 'react'
import { useMemo } from 'react'
import { useTracking } from 'react-tracking'

import RecentlyViewed from './RecentlyView'
import { RecommendProducts } from './RecommendProduct'
interface TypeAheadProps {
  searchQuery?: string
  debouncedSearchValue?: string
  recentSearchValues: any
  changeRecentSearchValues: (value: string[] | undefined) => void
}

const TypeAhead: React.FC<TypeAheadProps> = ({
  recentSearchValues,
  debouncedSearchValue = '',
  changeRecentSearchValues
}) => {
  const results = useTypeHeadAlgoliaSearch(debouncedSearchValue)

  const {
    search_recentView_data,
    search_recommendProducts_data,
    query,
    topSearch,
    topBrands,
    topCategories
  } = results || []

  useEffect(() => {
    if (query) {
      sessionStorage.setItem('searchQuery', query)
    }
    if (query === '') {
      sessionStorage.removeItem('searchQuery')
    }
  }, [query])
  const noSearchResults =
    isEmpty(search_recentView_data) &&
    isEmpty(search_recommendProducts_data) &&
    isEmpty(topSearch) &&
    isEmpty(topBrands) &&
    isEmpty(topCategories)

  const h3TextVariants = useMemo(
    () => ({
      topSearches: (query: string) =>
        query ? 'Top Searches' : 'Trending Searches',
      topBrands: (query: string) => (query ? 'Top Brands' : 'Trending Brands'),
      topCategories: (query: string) =>
        query ? 'Top Categories' : 'Trending Categories',
      recommendedProducts: 'Recommended Products',
      trendingProducts: 'Trending Products',
      yourRecentSearches: 'Your Recent Searches'
    }),
    []
  )

  const { trackEvent } = useTracking()

  const handleAnchorClick = useCallback(
    (h3TextVariant: string, itemValue: string) => {
      trackEvent({
        eventName: 'typeAheadLinkClicked',
        typeAheadSectionTitle: h3TextVariant,
        typeAheadLinkTitle: itemValue
      })
    },
    [trackEvent]
  )

  return (
    <div
      className={classnames(
        'typeahead-wrapper bg-[#fff] border border-borderDarkColor p-[10px] pl-3 lg:!p-6 text-[16px]',
        { block: !noSearchResults, 'element-hidden': noSearchResults }
      )}
    >
      <div className="typeahead-content">
        <div
          className={classnames(
            'mb-8 border-b border-borderDarkColor pb-8 element-hidden legacy:block min-[1310px]:flex',
            {
              '!hidden':
                (isEmpty(recentSearchValues) &&
                  isEmpty(search_recentView_data)) ||
                query
            }
          )}
        >
          {recentSearchValues?.length > 0 ? (
            <div className="recent-search min-[1310px]:w-[266px] mb-4">
              <h3 className="mb-3 font-bold text-base">
                {h3TextVariants?.yourRecentSearches}
              </h3>
              <ul className="font-normal">
                {recentSearchValues.map(item => (
                  <li
                    key={item}
                    className="mb-4 text-[14px] cursor-pointer"
                    onClick={() => {
                      const encodeValue = encodeURIComponent(trim(item))
                      window.location.href = `/search?typeAheadSuggestion=true&fromRecentHistory=true&Ntt=${encodeValue}`
                    }}
                  >
                    {item}
                  </li>
                ))}
                <li
                  className="text-primaryColor text-[14px] cursor-pointer"
                  onClick={() => {
                    changeRecentSearchValues(undefined)
                  }}
                >
                  Clear all recent searches
                </li>
              </ul>
            </div>
          ) : null}
          <RecentlyViewed searchRecentViewData={search_recentView_data} />
        </div>

        <div className="min-[360px]:flex legacy:block min-[1310px]:flex max-[976px]:justify-between">
          <div className="left-fields min-[1310px]:w-[250px]">
            {topSearch.length > 0 ? (
              <div>
                <h3 className="mb-3 font-bold text-base">
                  {h3TextVariants.topSearches(query)}
                </h3>
                <ul className="font-normal">
                  {topSearch.map(text => (
                    <li className="mb-4 leading-[18px]" key={text}>
                      <a
                        href={`/search?typeAheadSuggestion=true&typeAheadRedirect=true&isTypeAheadSearch=true&Ntt=${encodeURIComponent(
                          trim(text)
                        )}`}
                        data-name={text}
                        className="text-[14px] legacy:text-[16px] font-normal !text-[#2d2d2d] hover:underline"
                        onClick={() =>
                          handleAnchorClick(
                            h3TextVariants.topSearches(query),
                            text
                          )
                        }
                      >
                        {text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {topBrands?.length > 0 ? (
              <div className="element-hidden legacy:block">
                <h3 className="mb-3 font-bold text-base">
                  {h3TextVariants.topBrands(query)}
                </h3>
                <ul className="font-normal">
                  {topBrands.map(item => (
                    <li className="mb-4 leading-[18px]" key={item.value}>
                      <a
                        href={`${item?.seoURl}?pdptypeAheadSearchInput=${query}&tNtt=${query}&tAt=Brands&tAv=${item.value}`}
                        data-name={item.value}
                        className="text-[14px] legacy:text-[16px] font-normal !text-[#2d2d2d] hover:underline"
                        onClick={() =>
                          handleAnchorClick(
                            h3TextVariants.topBrands(query),
                            item.value
                          )
                        }
                      >
                        {item.value}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {topCategories?.length > 0 ? (
              <div className="element-hidden legacy:block">
                <h3 className="mb-3 font-bold text-base">
                  {h3TextVariants.topCategories(query)}
                </h3>
                <ul className="font-normal">
                  {topCategories.map(item => (
                    <li className="mb-4 leading-[18px]" key={item.value}>
                      <a
                        href={`${item?.seoURl}?pdptypeAheadSearchInput=${query}&tNtt=${query}&tAt=Categories&tAv=${item.value}`}
                        data-name={item.value}
                        className="text-[14px] legacy:text-[16px] font-normal !text-[#2d2d2d] hover:underline"
                        onClick={() =>
                          handleAnchorClick(
                            h3TextVariants.topCategories(query),
                            item.value
                          )
                        }
                      >
                        {item.value}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>

          <RecommendProducts
            searchRecommendProductsData={search_recommendProducts_data}
            searchQuery={query}
          />
        </div>
      </div>
      <style global jsx>{`
        @media (max-width: 975.9px) {
          .typeahead-wrapper.pl-3 {
            padding-left: 0.75rem !important;
          }
        }
        .left-fields .mb-4 {
          margin-bottom: 1rem !important;
        }
        .left-fields .mb-8 {
          margin-bottom: 2rem !important;
        }
      `}</style>
    </div>
  )
}

export default TypeAhead
