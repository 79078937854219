export const QUERY_KEY_PDP_LESSON_MODAL_CONTENT = 'pdp-lesson-modal-content'

export const QUERY_KEY_PDP_LESSON_BY_STORE = 'pdp-lesson-by-store'

export const QUERY_KEY_PDP_WARNING_TIPS = 'pdp-get-warning-tips'

export const QUERY_KEY_PDP_PRODUCT_IMAGE = 'pdp-product-images'
export const QUERY_KEY_PDP_PAGE_INFO = 'pdp-page-info'
export const QUERY_KEY_PDP_PAGE_LAYOUT = 'pdp-page-layout'
export const QUERY_KEY_PDP_PAYPAL_IN_4_CONFIG = 'pdp-pay-in-4-config'
export const QUERY_KEY_PDP_PRO_COVERAGE = 'pdp-pro-coverage'
export const QUERY_KEY_PDP_PROMO_MESSAGE = 'pdp-promo-message'
