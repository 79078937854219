import { last, replace, split, trim } from 'lodash'

export const formatSEOUrl = (value: string, isCategory = false): string => {
  if (!value || /\dA-Z/i.test(value)) return ''

  const lastLevelValue = isCategory ? last(split(value, ' > '))! : value
  const withoutSpecialChars = replace(lastLevelValue, /[^-/ \dA-Z]/gi, '')
  const formattedUrl = replace(trim(withoutSpecialChars), /[\s/-]+/g, '-')

  return isCategory ? `/${formattedUrl}.gc` : `/${formattedUrl}`
}
