import { useHeaderFooterData } from '@gc/common-lib/hooks/global'
import { initAudioEyeFn } from '@gc/common-lib/inject-scripts/audioEyeFn'
import { initCallEmailSubmitSuccessMessages } from '@gc/common-lib/inject-scripts/callEmailSubmitSuccessMessages'
import { initHelpButton } from '@gc/common-lib/inject-scripts/helpButton'
import { initGCIData } from '@gc/common-lib/utils/window'
import { useQueryClient } from '@tanstack/react-query'
import { useMount } from 'ahooks'

export const ThirdPartJSInit: React.FC = () => {
  const queryClient = useQueryClient()
  const { storeConfig } = useHeaderFooterData()

  useMount(() => {
    ;(window as any).dataLayer = (window as any).dataLayer || []

    // Set queryClient to window，have to above initGCIData
    ;(window as any).queryClient = queryClient

    initGCIData(queryClient)

    setTimeout(() => {
      initHelpButton()
    }, 500)

    // init GCI.callEmailSubmitSuccessMessages function
    if (
      process.env.NODE_ENV !== 'development' &&
      !(window as any).GCI?.callEmailSubmitSuccessMessages
    ) {
      initCallEmailSubmitSuccessMessages()
    }

    // init AudioEye function and run
    if (
      process.env.NODE_ENV !== 'development' &&
      storeConfig?.value?.audioEyeEnabled
    ) {
      initAudioEyeFn(storeConfig?.value?.audioEyeEnabled)
      setTimeout(() => {
        ;(window as any).GCI.audioEye_whenDOMLoads()
      }, 1000)
    }
  })

  return null
}
