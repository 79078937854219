import { liteClient } from 'algoliasearch/lite'

export const algoliaClient = liteClient(
  process.env.NEXT_PUBLIC_ALGOLIA_APPID as string,
  process.env.NEXT_PUBLIC_ALGOLIA_APIKEY as string,
  {
    baseHeaders: { referer: process.env.NEXT_PUBLIC_AJAX_ORIGIN as string },
    timeouts: {
      connect: 3000,
      read: 3000,
      write: 10000
    }
  }
)
