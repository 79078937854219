import { isArrayAndNotEmpty } from '@gc/common-lib/utils/lodash'
import type { MappingAlgorithm } from 'antd'
import { ConfigProvider, Rate as ARate } from 'antd'
import type { ComponentToken } from 'antd/es/rate/style'
import type { AliasToken } from 'antd/es/theme/internal'
import { reduce } from 'lodash'

export interface IRateProps {
  /* rating: rating point allow .5 half */
  rating: number
  /* showRatingDesc: hover show description flag */
  showRatingDesc?: boolean
  /* ratingDescArr: number[] of rates */
  ratingDescArr?: number[]
  className?: string
  ratingCount?: number | string
  containerClassName?: string
  rateConfigProvider?:
    | (Partial<ComponentToken> &
        Partial<AliasToken> & {
          algorithm?: boolean | MappingAlgorithm | MappingAlgorithm[]
        })
    | undefined
}

function Rate(props: IRateProps): React.ReactElement {
  const {
    rating,
    showRatingDesc,
    ratingDescArr = [],
    className,
    ratingCount,
    containerClassName = '',
    rateConfigProvider
  } = props

  let ratingTotal = 0
  if (isArrayAndNotEmpty(ratingDescArr)) {
    ratingTotal = reduce(
      ratingDescArr,
      (pre, cur) => {
        return pre + cur
      },
      0
    )
  }

  return (
    <>
      <div
        className={`group star-container inline-block relative cursor-pointer ${containerClassName}`}
        aria-label={`Rated ${rating / 2} out of 5 stars`}
        role="img"
      >
        <div className="inline-block" aria-hidden="true">
          <ConfigProvider
            theme={{
              components: {
                Rate: {
                  ...rateConfigProvider
                }
              }
            }}
          >
            <ARate
              defaultValue={rating / 2}
              className={`text-sm cursor-pointer text-[#FFB401] ${className}`}
              value={rating / 2}
              allowHalf
              disabled
            />
          </ConfigProvider>
        </div>

        {(ratingCount || ratingCount === 0) && (
          <span
            className="ml-1 text-xs font-normal rating-count"
            aria-hidden="true"
          >
            ({ratingCount})
          </span>
        )}

        {showRatingDesc && (
          <div
            className="rating-desc hidden group-hover:block absolute w-80 md:w-[488px] left-0 top-[20px] pb-6 bg-[#e7e7e7] z-10"
            aria-hidden="true"
          >
            <div className="px-2.5 pt-4 pb-2.5 text-2xl leading-7 text-white bg-textNormalColor font-semibold">
              Rating Distribution
            </div>

            <ul className="flex flex-col-reverse pt-5 pb-4 pl-2.5 mb-0 w-auto pr-[50px]">
              {isArrayAndNotEmpty(ratingDescArr) &&
                ratingDescArr.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className="relative mb-1.5 h-6 border border-borderDarkColor bg-white"
                    >
                      <span className="inline-block relative z-10 pl-5 leading-6">
                        {index + 1} Stars
                      </span>
                      {ratingTotal && ratingTotal !== 0 && (
                        <div
                          className="absolute h-full top-0 left-0 bg-primaryColor z-0"
                          style={{ width: `${(item * 100) / ratingTotal}%` }}
                        />
                      )}
                    </li>
                  )
                })}
            </ul>

            <a className="ml-2.5 text-sm leading-4 underline text-primaryColor font-bold">
              See All User Reviews
            </a>
          </div>
        )}
      </div>
    </>
  )
}

export default Rate
