interface IFooterLegalProps {
  footerLegal: string
  loadClarip: (e: any) => void
}

function FooterLegal(props: IFooterLegalProps): React.ReactElement {
  const { footerLegal, loadClarip } = props

  return (
    <section>
      <div className="footer-legal-wrap w-full h-[60px] bg-[#000]">
        <div
          className="flex flex-wrap justify-center items-center mx-auto h-full font-bold text-white lg:justify-between"
          dangerouslySetInnerHTML={{ __html: footerLegal }}
          onClick={loadClarip}
        />
      </div>

      <style jsx global>{`
        .legal-footer-wrap,
        .footer-legal-wrap > div {
          width: 100%;
          max-width: 1280px;
          font-size: 12px;
          @media (max-width: 767px) {
            text-align: center;
          }
        }

        .legal-footer-wrap span {
          font-family: var(--font-montserrat), sans-serif;
        }
        .legal-footer-wrap a:not(.hidden),
        .footer-legal-wrap > div a:not(.hidden) {
          display: inline-block;
          margin-left: 23px;
          color: #fff;
          font-weight: normal;
          font-size: 10px;
          line-height: 18px;
          font-family: var(--font-montserrat), Arial, Helvetica, sans-serif;

          &:hover {
            color: #fff;
            text-decoration: underline;
          }
        }
        .legal-footer-wrap {
          display: flex;
          justify-content: space-between;
        }
        .legal-footer-wrap a:not(.hidden):hover,
        .footer-legal-wrap > div a:not(.hidden):hover {
          color: #fff;
          text-decoration: underline;
        }

        .legal-footer-wrap a.right,
        .footer-legal-wrap > div a.right {
          float: none;
        }
      `}</style>
    </section>
  )
}

export default FooterLegal
