import { RESPONSE_SUCCESS_CODE } from '../../constants/responseCode'
import { logger } from '../../utils/logger'
import ATGService, { thirdPartyService } from '../request'

export interface IUserProfile {
  lastName: string
  isGCProUser: boolean
  gCProUserShipId?: string
  login: string
  gCProApplicationStatus: string
  firstName: string
  phoneNumber: string
  gcProAgentUser?: any
  isGCProExperience: boolean
  middleName?: string
  id: string
  gCProUserBillId?: string
  email?: string
}

export function sendUserResetPWEmail(payload: { login: string }): Promise<any> {
  return ATGService({
    url: '/atg/account/myaccountActor/emailPasswordResetLink',
    method: 'post',
    data: { ...payload },
    needSession: true,
    needShowErrorMessage: false
  })
}

export function sendUserNewsAndOffersEmail(params: {
  api: string
  bodyFormData: FormData
  callBack?: () => void
}) {
  return thirdPartyService({
    method: 'post',
    url: params.api,
    data: params.bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
    .then(() => {
      return params.callBack ? params.callBack() : null
    })
    .catch(error => {
      logger.error({
        message: 'sendUserNewsAndOffersEmail error --->',
        error
      })
    })
}

export async function checkEmailApi(email: string | undefined): Promise<any> {
  const { data } = await ATGService({
    url: '/ngp/rest/actor/DropletActor/checkIfProfileRegisteredByEmail',
    method: 'post',
    data: { email },
    needLoading: false
  })

  return data?.value
}

export interface IUserProfileData {
  lastName: string
  isGCProUser: boolean
  login: string
  gCProApplicationStatus: string
  firstName: string
  phoneNumber: string
  hashedProfileID: string
  isGCProExperience: boolean
  id: string
}
export const userLogin = async (payload: {
  login: string
  password: string
  needWholeErrorData?: boolean
}): Promise<IUserProfileData | null> => {
  const { needWholeErrorData = false, ...restPayload } = payload
  const { data } = await ATGService({
    url: '/atg/userprofiling/ProfileActor/login',
    method: 'post',
    data: { ...restPayload },
    needSession: true,
    needWholeErrorData
  })

  if (data.statusCode === RESPONSE_SUCCESS_CODE) {
    return data
  }
  return null
}

export const userSignOut = async () => {
  const res = await ATGService({
    url: '/atg/userprofiling/ProfileActor/logout',
    method: 'post',
    needSession: true,
    clearSession: true
  })

  return res?.data || {}
}

export const userSignUp = async (payload: {
  email: string
  firstName: string
  middleName: string
  lastName: string
  monthOfBirth: string
  dayOfBirth: string
  password: string
  confirmPassword: string
  // for data track
  pageLocation: string
}): Promise<any> => {
  const { data } = await ATGService({
    url: '/atg/userprofiling/ProfileActor/create-SPA',
    method: 'post',
    data: { ...payload },
    needSession: true,
    needShowErrorMessage: false
  })

  if (data.statusCode === RESPONSE_SUCCESS_CODE) {
    return data
  }
  return null
}

export const getUserProfile = async (): Promise<IUserProfile> => {
  const { data } = await ATGService({
    url: '/atg/account/myaccountActor/overview',
    method: 'GET'
  })
  return data?.profile
}
