import { Countdown as ACountdown } from '../statistic'

interface IGCCountdown {
  value?: number | string
  format?: string
  onFinish?: () => void
  onChange?: (value?: number | string) => void
  valueStyle?: React.CSSProperties
}

export default function CountDown(props: IGCCountdown): React.ReactElement {
  return <ACountdown {...props} />
}
