import type { IFooterLinksProps } from '@gc/common-lib/api/page-data'
import { isArrayAndNotEmpty } from '@gc/common-lib/utils/lodash'
import { useMemo } from 'react'

function FooterLinks(props: {
  footerLinkConfig: IFooterLinksProps[]
  bbbLinksHTML: string
}): React.ReactElement {
  const { footerLinkConfig = [], bbbLinksHTML } = props
  const explicitImgWidth = useMemo(() => {
    const newLinkHTML = bbbLinksHTML.replace(
      /<img/g,
      '<img width="108px" height="32px" '
    )
    return newLinkHTML
  }, [bbbLinksHTML])
  return (
    <section>
      <div className="overflow-hidden max-w-screen-xl px-5 mx-auto md:px-0 footer-links-wrap">
        {isArrayAndNotEmpty(footerLinkConfig) &&
          footerLinkConfig.map(item => {
            return (
              <div
                className="float-left md:pb-12 footer-links-item px-[10px] w-[162px]"
                key={item.title}
              >
                <h6 className="footer-heading">{item.title}</h6>
                <ul className="font-normal">
                  {item.links.map(i => {
                    return (
                      <li key={i.text}>
                        <a href={i.href}>{i.text}</a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}

        <div className="float-right pb-12 w-auto how-we-doing px-[10px] md:min-w-[256px]">
          <h6 className="footer-heading">How Are We Doing?</h6>
          <a
            href="/customer-service/email-us?deptId=customer_service"
            className="text-base provide-feedback text-textNormalColor"
          >
            Provide Feedback
          </a>
          <div
            className="footer-bbb-links-wrap"
            dangerouslySetInnerHTML={{ __html: explicitImgWidth }}
          />
        </div>
      </div>

      <style global jsx>{`
        .footer-bbb-links-wrap .bbb-links {
          display: flex;
          align-items: center;
          justify-content: row;
          @media (min-width: 768px) {
            justify-content: space-between;
          }

          .bbb-img {
            display: inline-block;
            width: 48px;
            height: 93px;
            text-indent: -9999px;
            background: url(/assets/images/common/sprite-gc-global.png)
              no-repeat -56px 0;
          }
          .bbb-caru-link img {
            align-self: baseline;
            width: 107px;
          }
        }
      `}</style>

      <style jsx>{`
        .footer-links-wrap {
          max-width: 1280px;

          .footer-heading {
            margin-bottom: 10px;
            color: #000;
            font-family:
              var(--font-montserrat), 'Arial Black', Arial, Helvetica,
              sans-serif;
            font-size: 18px;
            line-height: 27px;
            @media (max-width: 767px) {
              font-size: 14px;
              white-space: normal;
            }
          }
          .footer-links-item {
            &:nth-child(1) {
              margin-right: calc((100% * 0.1107) - 23px);
            }
            &:nth-child(2) {
              margin-right: calc((100% * 0.1966) - 157px);
            }
            &:nth-child(3) {
              width: 200px;
            }
          }

          & ul li {
            height: 22px;
            & a {
              display: inline-block;
              margin-bottom: 2px;
              color: #000;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              @media (max-width: 767px) {
                font-size: 12px;
                white-space: normal;
              }

              &:hover {
                color: var(--primaryLessDarkColor);
              }
            }
          }

          .how-we-doing {
            margin-right: calc((100% * 0.2664) - 211px);
          }

          .provide-feedback {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            height: 34px;
            margin-top: 15px;
            margin-bottom: 20px;
            font-family: var(--font-montserrat), Arial, Helvetica, sans-serif;
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
            border: 1px solid var(--textNormalColor);
            @media (max-width: 767px) {
              width: 145px;
              font-size: 12px;
              white-space: normal;
            }
          }
        }
      `}</style>
    </section>
  )
}

export default FooterLinks
