import { getClaripInfo } from '@gc/common-lib/api/footer'
import { sendUserNewsAndOffersEmail } from '@gc/common-lib/api/user'
import {
  GC_DESKTOP_FOOTER_EMAIL_SIGNUP_BUTTON_LABEL_DEFAULT,
  GC_DESKTOP_FOOTER_EMAIL_SIGNUP_HEADER_TEXT_DEFAULT,
  GC_DESKTOP_FOOTER_EMAIL_SIGNUP_INPUT_TITLE_DEFAULT
} from '@gc/common-lib/constants/global'
import { useHeaderFooterData } from '@gc/common-lib/hooks/global'
import Button from '@gc/ui-components/button'
import Form, { FormItem, useForm } from '@gc/ui-components/form'
import Input from '@gc/ui-components/input'
import { useQuery } from '@tanstack/react-query'
import GCCheckbox from '@uc/basic/checkbox'
import classNames from 'classnames'
import { useState } from 'react'

const FooterSignUpForm: React.FC = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const { messageContent, storeConfig = { value: { enableClarip: true } } } =
    useHeaderFooterData()

  const [form] = useForm()

  const { data: claripInfo } = useQuery({
    queryKey: ['claripInfo'],
    queryFn: () => getClaripInfo(33)
  })

  const handleSignUp = async () => {
    const bodyFormData = new FormData()
    const values = await form.validateFields()
    if (values) {
      const api =
        storeConfig?.value?.enableClarip === true
          ? 'https://gci.clarip.com/api/consent-management/universal'
          : '/pages/manage-subscription'

      const { email } = form.getFieldsValue()

      bodyFormData.append('email', email)
      bodyFormData.append('id', '33')
      bodyFormData.append('consentCap-33', 'on')
      bodyFormData.append('consent', 'yes')

      const callBack = () => {
        setSubmitSuccess(true)
      }

      sendUserNewsAndOffersEmail({ api, bodyFormData, callBack }).catch(err =>
        console.log(err)
      )
    }
  }

  return (
    <div className="px-4 pt-7 text-center md:px-0 pb-[10px] bg-borderMoreLightColor lg:pb-[30px]">
      <div
        className={classNames(
          'mb-3.5 font-black text-lg',
          (submitSuccess && 'hidden') || ''
        )}
        style={{ lineHeight: '22px' }}
      >
        {messageContent?.contentItem
          ?.GC_DESKTOP_FOOTER_EMAIL_SIGNUP_HEADER_TEXT ||
          GC_DESKTOP_FOOTER_EMAIL_SIGNUP_HEADER_TEXT_DEFAULT}
      </div>
      <Form
        form={form}
        onFinish={handleSignUp}
        name="emailForm"
        className={classNames(
          'footer-sign-up flex justify-center flex-col md:flex-row',
          (submitSuccess && 'hidden') || ''
        )}
        colon={false}
      >
        <div>
          <FormItem
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Please enter your email!'
              },
              {
                type: 'email',
                message: `Please include an "@" in the email address!`
              }
            ]}
            validateTrigger="onBlur"
            className="mb-3 text-center md:text-left"
          >
            <Input
              className="font-normal md:mr-[14px] rounded-none"
              placeholder="Enter Email Address (Required):"
              title={
                messageContent?.contentItem
                  ?.GC_DESKTOP_FOOTER_EMAIL_SIGNUP_INPUT_TITLE ||
                GC_DESKTOP_FOOTER_EMAIL_SIGNUP_INPUT_TITLE_DEFAULT
              }
              style={{
                width: 458,
                maxWidth: '100%',
                height: 36
              }}
            />
          </FormItem>

          {storeConfig?.value?.enableClarip === true && (
            <FormItem
              name="consentCap-33"
              valuePropName="checked"
              className="text-center md:text-left"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            'Please check this box if you want to processed'
                          )
                        )
                }
              ]}
            >
              <GCCheckbox className="items-center">
                <span className="relative text-xs">{claripInfo?.content}</span>
              </GCCheckbox>
            </FormItem>
          )}
        </div>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            height={35}
            width={156}
            className="text-base md:mt-0 mt-[15px] font-bold"
          >
            {messageContent?.contentItem
              ?.GC_DESKTOP_FOOTER_EMAIL_SIGNUP_BUTTON_LABEL ||
              GC_DESKTOP_FOOTER_EMAIL_SIGNUP_BUTTON_LABEL_DEFAULT}
          </Button>
        </FormItem>
      </Form>

      <p
        className={classNames(
          'color-textNormalColor text-base text-center',
          (!submitSuccess && 'hidden') || ''
        )}
      >
        Thank you for signing up!
      </p>
      <style jsx global>{`
        form.footer-sign-up .ant-form-item-label {
          display: none;
        }
        form.footer-sign-up input:not(.ant-input-status-error):hover {
          border-color: #ddd;
        }
        form.footer-sign-up .ant-checkbox {
          background-color: #fff;
        }
      `}</style>
    </div>
  )
}

export default FooterSignUpForm
