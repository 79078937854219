import {
  BBB_LINKS_DEFAULT,
  MOBILE_CONNECT_LINKS_DEFAULT
} from '@gc/common-lib/constants/global'
import { loadClarip } from '@gc/common-lib/helper/business/clarip'
import { useHeaderFooterData } from '@gc/common-lib/hooks/global'
import { useResponsive } from 'ahooks'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { useTracking } from 'react-tracking'

import FooterLegal from './FooterLegal'
import FooterLinks from './FooterLinks'
import FooterSignUpForm from './FooterSignUpForm'
import MobileFooter from './MobileFooter'
const FooterEngine = dynamic(() => import('./FooterEngine'))

function Footer() {
  const responsive = useResponsive()
  const { trackEvent } = useTracking()

  const [showMobileFooter, setShowMobileFooter] = useState(true)

  const {
    footer: {
      phoneNumber,
      footerLinkConfig,
      footerLegal = '',
      bbbLinksHTML = BBB_LINKS_DEFAULT,
      spaPhoneNumber = '',
      mobileConnectLinks = MOBILE_CONNECT_LINKS_DEFAULT
    }
  } = useHeaderFooterData()

  useEffect(() => {
    trackEvent({
      pageLocation: 'footer',
      eventName: 'footerLoaded'
    })
  }, [])

  useEffect(() => {
    setShowMobileFooter(!responsive?.legacy)
  }, [responsive?.legacy])

  return (
    <div className="m-auto h-auto footer-container print:hidden">
      <FooterSignUpForm />
      {/* Although the footer needs to be hidden, it still needs to maintain SEO */}
      <section
        className={`footer-desktop ${showMobileFooter ? 'hidden' : 'block'}`}
      >
        <FooterEngine phoneNumber={phoneNumber} />
        <FooterLinks
          footerLinkConfig={footerLinkConfig}
          bbbLinksHTML={bbbLinksHTML}
        />
        <FooterLegal footerLegal={footerLegal} loadClarip={loadClarip} />
      </section>

      <section
        className={`footer-mobile ${showMobileFooter ? 'block' : 'hidden'}`}
      >
        <MobileFooter
          phoneNumber={phoneNumber}
          spaPhoneNumber={spaPhoneNumber}
          bbbLinksHTML={bbbLinksHTML}
          mobileConnectLinks={mobileConnectLinks}
          loadClarip={loadClarip}
        />
      </section>
    </div>
  )
}

export default Footer

export const name = `Footer`
