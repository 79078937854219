import { GC_HEADER_LIVE_HELP_PHONE_NUMBER_DEFAULT } from '@gc/common-lib/constants/global'
import { withErrorBoundary } from '@gc/common-lib/hoc/withErrorBoundary'
import { useHeaderFooterData } from '@gc/common-lib/hooks/global'
import { useHeaderUserInfo } from '@gc/common-lib/hooks/header'
import InnerHTML from 'dangerously-set-html-content'
import { useEffect } from 'react'
const PromoMessage: React.FC = () => {
  const { promotionBanner } = useHeaderFooterData()
  const { callCenterNumber = GC_HEADER_LIVE_HELP_PHONE_NUMBER_DEFAULT } =
    useHeaderUserInfo()
  useEffect(() => {
    const headerRoll = document.querySelector('span.js-phone-number-headerroll')
    if (headerRoll?.innerHTML) {
      headerRoll.innerHTML = `<a href="tel:${callCenterNumber}" class="cta-link">${callCenterNumber}</a>`
    }
  }, [callCenterNumber])

  if (!promotionBanner) return null

  return (
    <InnerHTML
      html={promotionBanner}
      className="pre-header-roll-wrapper"
      id="preHeaderRollWrapper"
    />
  )
}

export default withErrorBoundary(PromoMessage)
