import {
  algoliaMainIndexName,
  algoliaSearchSuggestionIndexName
} from '@constants/algolia-plp/configure'
import { QUERY_KEY_TYPE_HEAD_KEY } from '@constants/algolia-plp/typeHead'
import { logger } from '@gc/common-lib/utils/logger'
import { formatSEOUrl } from '@helper/business/algolia-plp/algolia-seo'
import { algoliaClient } from '@helper/utils/algoliaClient'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import type { AlgoliaTypeHeadSearch } from '@typings/algolia-plp'
import { useLocalStorageState } from 'ahooks'
import { compact, forEach, get, keys, last, take, uniqBy } from 'lodash'
import { useMemo } from 'react'

import { useAlgoliaSEOData } from './context'

export const useTypeHeadAlgoliaSearch = queries => {
  const [recentItems = []] = useLocalStorageState<string[] | undefined>(
    'recentItems'
  )
  const { seoData } = useAlgoliaSEOData()

  const filtersObjID = useMemo(() => {
    if (recentItems.length === 0) {
      return null
    }
    return recentItems.map(objectID => `objectID:"${objectID}"`).join(' OR ')
  }, [recentItems])

  const searchQuery = queries?.length > 2 ? queries : ''
  const search_suggestions = {
    indexName: algoliaSearchSuggestionIndexName,
    params: {
      highlightPostTag: '__/ais-highlight__',
      highlightPreTag: '__ais-highlight__',
      query: searchQuery,
      attributesToRetrieve: ['*'],
      attributesToSnippet: ['*:20'],
      enableABTest: false,
      explain: ['*'],
      facets: ['*'],
      getRankingInfo: true,
      hitsPerPage: 10,
      page: 0,
      responseFields: ['*']
    }
  }

  const search_recentView_params =
    filtersObjID && !searchQuery
      ? {
          indexName: algoliaMainIndexName,
          params: {
            filters: filtersObjID,
            highlightPostTag: '__/ais-highlight__',
            highlightPreTag: '__ais-highlight__'
          }
        }
      : undefined

  const startDate = get(
    seoData,
    'query.range.startDate',
    `${Math.floor(Date.now() / 1000)}`
  ).replace(/^:/, '')

  const search_recommendProducts_params = {
    indexName: algoliaMainIndexName,
    params: {
      highlightPostTag: '__/ais-highlight__',
      highlightPreTag: '__ais-highlight__',
      facetFilters: ['retailOnly:-true', 'isSerial:-true'],
      facets: ['brand', 'categories.lvl0'],
      numericFilters: [`startDate<=${startDate}`],
      maxValuesPerFacet: 3,
      queryType: 'prefixLast' as const,
      hitsPerPage: 6,
      query: searchQuery
    }
  }

  const searchRequests = compact([
    search_suggestions,
    search_recentView_params,
    search_recommendProducts_params
  ])
  const isValidTypeHeadQuery =
    isNaN(Number(searchQuery?.replace(/\s/g, ''))) || searchQuery === ''

  const { data } = useQuery<any>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEY_TYPE_HEAD_KEY, searchQuery],
    queryFn: () => algoliaClient.search(searchRequests),
    placeholderData: isValidTypeHeadQuery ? keepPreviousData : null,
    enabled: isValidTypeHeadQuery
  })

  let searchSuggestionsIndex = ''
  let searchSuggestions = []
  let search_recentView_data = []
  let search_recommendProducts_data = []
  let query = ''

  if (data?.results?.length === 2) {
    ;[
      {
        hits: searchSuggestions = [],
        query = '',
        index: searchSuggestionsIndex = ''
      } = {},
      { hits: search_recommendProducts_data = [] } = {}
    ] = data?.results || []
  } else if (data?.results?.length === 3) {
    ;[
      {
        hits: searchSuggestions = [],
        query = '',
        index: searchSuggestionsIndex = ''
      } = {},
      { hits: search_recentView_data = [] } = {},
      { hits: search_recommendProducts_data = [] } = {}
    ] = data?.results || []
  }

  const { topSearch, topBrands, topCategories } = useMemo(() => {
    const topSearch = [] as string[]
    const topBrands = [] as AlgoliaTypeHeadSearch.Brand[]
    const topCategories = [] as AlgoliaTypeHeadSearch.Category[]
    const typeHeadIndexName =
      process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_SUGGESTION_INDEX_NAME?.split(
        '_query'
      )[0] || ''
    try {
      forEach(take(searchSuggestions, 7), (item: any) => {
        if (item?.query && topSearch?.length < 7) {
          topSearch.push(item?.query)
        }
        const { exact_matches = {} } = item[typeHeadIndexName]?.facets ?? []
        const brand = exact_matches['brand'][0]?.value || ''
        const lastCategoryLevel =
          last(
            keys(exact_matches)?.filter(key => key?.startsWith('categories.'))
          ) ?? ''

        const categoryValue = get(exact_matches[lastCategoryLevel], `[0].value`)
        const lastSubCategory = categoryValue?.split('>')?.pop()?.trim()

        if (brand && topBrands.length < 3) {
          const seoUrl = formatSEOUrl(brand)
          const brandItem = { value: brand, seoURl: seoUrl }
          topBrands.push(brandItem)
        }

        if (lastSubCategory) {
          const seoUrl = formatSEOUrl(lastSubCategory, true)
          const categoryItem = { value: lastSubCategory, seoURl: seoUrl }
          topCategories.push(categoryItem)
        }
      })
    } catch (error) {
      logger.error(
        'turbo ~ topSearch,topBrands,topCategories  ~ error: %o',
        error
      )
    }

    return {
      topSearch,
      topBrands: uniqBy(topBrands, 'value'),
      topCategories: uniqBy(topCategories, 'value').slice(0, 3)
    }
  }, [searchSuggestions])

  return {
    searchSuggestions,
    search_recentView_data,
    search_recommendProducts_data,
    query,
    topSearch,
    topCategories,
    topBrands,
    searchSuggestionsIndex
  }
}
