import type { FooterTypings } from '../../typings/footer'
import ATGService, { thirdPartyService } from '../request'
interface ICurrencyCodesRes {
  data?: {
    statusCode: string
    result: FooterTypings.ICurrencyCode[]
  }
}
interface ICountriesRes {
  data?: {
    statusCode: string
    result: FooterTypings.IRegionCountries[]
  }
}

export const fetchCurrencyCodes: FooterTypings.CurrencyCodeType =
  async (): Promise<FooterTypings.ICurrencyCode[]> => {
    const res: ICurrencyCodesRes = await ATGService({
      url: '/atg/userprofiling/ProfileActor/getCurrencyCodes',
      method: 'get'
    })
    const currencyCodes = res?.data
    return currencyCodes?.statusCode === 'Success' ? currencyCodes?.result : []
  }

export const fetchCountries: FooterTypings.RegionCountriesType =
  async (): Promise<FooterTypings.IRegionCountries[]> => {
    const res: ICountriesRes = await ATGService({
      url: '/atg/userprofiling/ProfileActor/intPaymentModal',
      method: 'get'
    })
    const countries = res?.data

    return countries?.statusCode === 'Success' ? countries?.result : []
  }

export const getClaripInfo = async (id: number) => {
  const res = await thirdPartyService({
    url: `https://cdn.clarip.com/gci/pageId${id}.json`,
    method: 'GET'
  })
  return res?.data
}
