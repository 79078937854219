import type { AlgoliaPLPTypings } from '@typings/algolia-plp'
import type { IndexUiState } from 'instantsearch.js'
import { createContext, useContext } from 'react'
import type { InstantSearchApi } from 'react-instantsearch-core'

export interface IAlgoliaInstantSearchContext {
  instantSearch: InstantSearchApi
}

export interface IAlgoliaSEOContext {
  seoData: AlgoliaPLPTypings.ISEOData
  serverUrl: string
  endecaUiState: IndexUiState
  userDisplaySettings: { pageSize?: number }
}

export const AlgoliaInstantSearchContext = createContext(
  {} as IAlgoliaInstantSearchContext
)

export const useAlgoliaInstantSearch = () =>
  useContext(AlgoliaInstantSearchContext)

export const AlgoliaSEODataContext = createContext({} as IAlgoliaSEOContext)

export const useAlgoliaSEOData = () => useContext(AlgoliaSEODataContext)
