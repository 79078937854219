import { assign, isFunction } from 'lodash'

import { loadScript, loadStyle } from '../../utils/script'

const CLARIP_CONFIG = {
  host: 'gci.clarip.com',
  cdnHost: 'cdn.clarip.com',
  clientName: 'gci',
  cookieManagerId: 'cdc8667146323474ce81',
  assets: {
    cssLinks: [
      'donotsell/assets/css/donotsell-extended.min.css',
      'cookieconsent/assets/css/cookieconsent.min.css'
    ] as string[],
    jsLinks: [
      'donotsell/assets/js/block-3rd-parties.min.js',
      'donotsell/assets/js/donotsell-block.min.js',
      'cookieconsent/assets/js/cookieconsent.min.js',
      'cookieconsent/assets/js/clarip-cookie-manager.min.js'
    ] as string[]
  }
} as const

let firstLoadClarip = true

const loadClaripAssets = (onAssetsLoad?: () => void): void => {
  assign(window, {
    globalDnsDeployment: false,
    claripHost: CLARIP_CONFIG.host,
    claripCdnHost: CLARIP_CONFIG.cdnHost,
    clientName: CLARIP_CONFIG.clientName,
    claripClientName: CLARIP_CONFIG.clientName,
    claripCookieConsentHost: CLARIP_CONFIG.host,
    claripCookieManagerIdentifier: CLARIP_CONFIG.cookieManagerId,
    dnsControllerType: 'option-2',
    dnsSubmissionUrl: `https://${CLARIP_CONFIG.host}/dsr/success?brand=GC`,
    standardDsrFormUrl: `https://${CLARIP_CONFIG.host}/dsr/create?brand=GC&type=3`,
    createDoNotSellLink: 0,
    doNotSellLinkSelector: "a[href='/pages/data-request?clarip=1']",
    doNotSellCookieName: 'dnss_cookie',
    doNotSellCookieValue: '1',
    doNotSellCookieExpirationAge: 5475,
    dynamicDSRFormFieldsPrefill: true,
    enableGPC: true,
    enableEnforcementScope: false,
    domainList: [/clarip.com/]
  })

  CLARIP_CONFIG.assets.cssLinks.forEach(css =>
    loadStyle({
      href: `https://${CLARIP_CONFIG.cdnHost}/${CLARIP_CONFIG.clientName}/${css}`
    })
  )

  const loadScripts = (scripts: string[], index = 0): void => {
    if (index >= scripts.length) {
      if (isFunction(onAssetsLoad)) {
        setTimeout(onAssetsLoad, 1000)
      }
      return
    }

    loadScript({
      src: `https://${CLARIP_CONFIG.cdnHost}/${CLARIP_CONFIG.clientName}/${scripts[index]}`,
      onLoad: () => loadScripts(scripts, index + 1)
    })
  }

  loadScripts(CLARIP_CONFIG.assets.jsLinks)
}

export const loadClarip = (e): void => {
  const target = e?.target as HTMLAnchorElement
  const isClaripLink = target?.href?.includes('clarip=1')
  const isCookiePreferenceLink = target?.id?.includes('consent-management-tool')

  if (!(isClaripLink || isCookiePreferenceLink)) return

  e.preventDefault()

  const handleClaripClick = (): void => {
    if (isClaripLink) {
      window.createClaripDoNotSellManager()
      window.showDoNotSellManager()
    }

    if (isCookiePreferenceLink) {
      window.claripCookieManager.showClaripCookieManager()
    }
  }

  if (firstLoadClarip) {
    firstLoadClarip = false

    loadClaripAssets(handleClaripClick)
  } else {
    handleClaripClick()
  }
}
