import { keepPreviousData, useQuery } from '@tanstack/react-query'
import type currency from 'currency.js'
import { isEmpty } from 'lodash'
import { useCallback } from 'react'

import { getHeaderFooterInfoByAPI } from '../../api/global'
import type { IServerConfigData } from '../../api/header'
import {
  BBB_LINKS_DEFAULT,
  GC_DESKTOP_FOOTER_EMAIL_SIGNUP_BUTTON_LABEL_DEFAULT,
  GC_DESKTOP_FOOTER_EMAIL_SIGNUP_HEADER_TEXT_DEFAULT,
  GC_DESKTOP_FOOTER_EMAIL_SIGNUP_INPUT_TITLE_DEFAULT,
  GC_HEADER_FLYOUT_ACCOUNT_LOGGED_IN_MENUS_DEFAULT,
  GC_HEADER_FLYOUT_ACCOUNT_LOGGED_OUT_MENUS_DEFAULT,
  GC_HEADER_LIVE_HELP_ANOTHER_PHONE_NUMBER_DEFAULT,
  GC_HEADER_LIVE_HELP_BOTTOM_LINK_DEFAULT,
  GC_HEADER_LIVE_HELP_PHONE_NUMBER_DEFAULT,
  GC_HEADER_LIVE_HELP_SERVER_CONTACT_TIME_DEFAULT,
  GC_HEADER_LIVE_HELP_TOP_MESSAGE_DEFAULT,
  MOBILE_CONNECT_LINKS_DEFAULT
} from '../../constants/global'
import { QUERY_KEY_HEADER_FOOTER } from '../../constants/global/queryKey'
import { getQueryValue } from '../../utils/browser'
import { formatCurrency } from '../../utils/currency'

export const useFormatCurrency = (currencySymbol: string) => {
  return useCallback(
    (price: number | string | currency) =>
      formatCurrency(price, currencySymbol),
    [currencySymbol]
  )
}

const defaultHeaderFooterData: IServerConfigData = {
  recaptchaEnable: true,
  menu: [],
  footer: {
    phoneNumber: '',
    footerLinkConfig: [],
    bbbLinksHTML: BBB_LINKS_DEFAULT,
    footerLegal: '',
    spaPhoneNumber: '',
    mobileConnectLinks: MOBILE_CONNECT_LINKS_DEFAULT
  },
  messageContent: {
    contentItem: {
      GC_HEADER_FLYOUT_ACCOUNT_LOGGED_IN_MENUS:
        GC_HEADER_FLYOUT_ACCOUNT_LOGGED_IN_MENUS_DEFAULT,
      GC_HEADER_FLYOUT_ACCOUNT_LOGGED_OUT_MENUS:
        GC_HEADER_FLYOUT_ACCOUNT_LOGGED_OUT_MENUS_DEFAULT,
      GC_DEFAULT_PRE_HEADER_ROLL: '',
      GC_HEADER_LIVE_HELP_TOP_MESSAGE: GC_HEADER_LIVE_HELP_TOP_MESSAGE_DEFAULT,
      GC_HEADER_LIVE_HELP_PHONE_NUMBER:
        GC_HEADER_LIVE_HELP_PHONE_NUMBER_DEFAULT,
      GC_HEADER_LIVE_HELP_ANOTHER_PHONE_NUMBER:
        GC_HEADER_LIVE_HELP_ANOTHER_PHONE_NUMBER_DEFAULT,
      GC_HEADER_LIVE_HELP_BOTTOM_LINK: GC_HEADER_LIVE_HELP_BOTTOM_LINK_DEFAULT,
      GC_HEADER_LIVE_HELP_SERVER_CONTACT_TIME:
        GC_HEADER_LIVE_HELP_SERVER_CONTACT_TIME_DEFAULT,
      GC_DESKTOP_FOOTER_EMAIL_SIGNUP_HEADER_TEXT:
        GC_DESKTOP_FOOTER_EMAIL_SIGNUP_HEADER_TEXT_DEFAULT,
      GC_DESKTOP_FOOTER_EMAIL_SIGNUP_INPUT_TITLE:
        GC_DESKTOP_FOOTER_EMAIL_SIGNUP_INPUT_TITLE_DEFAULT,
      GC_DESKTOP_FOOTER_EMAIL_SIGNUP_BUTTON_LABEL:
        GC_DESKTOP_FOOTER_EMAIL_SIGNUP_BUTTON_LABEL_DEFAULT,
      SERIAL_ITEM_POUNDS_TEXT_KEY: 'lb'
    }
  },
  storeConfig: {
    value: {
      claripConsentJSUrl: 'https://cdn.clarip.com/gci/clarip_consent.js',
      enableClarip: true,
      audioEyeEnabled: true,
      loginMaxAttempts: 3,
      enableLoginRecaptcha: true,
      internationalContextEnabled: false
    }
  }
}
export const useHeaderFooterData = () => {
  const isQAUser = getQueryValue('qauser') === 'yes'

  const { data: queryData } = useQuery({
    queryKey: [QUERY_KEY_HEADER_FOOTER],
    queryFn: () => getHeaderFooterInfoByAPI(),
    placeholderData: keepPreviousData
  })

  const data: IServerConfigData = isEmpty(queryData)
    ? defaultHeaderFooterData
    : queryData

  // qaUser need hidden google recaptcha
  data.recaptchaEnable = data.recaptchaEnable && !isQAUser
  return data
}
