import { IMAGE_PREFIX } from '@constants/media'
import { specifyImageSize } from '@gc/common-lib/utils/string'
import Image from '@gc/ui-components/image'
import type { AlgoliaTypeHeadSearch } from '@typings/algolia-plp'
import { isEmpty } from 'lodash'

const RecentlyViewed = ({
  searchRecentViewData
}: {
  searchRecentViewData: AlgoliaTypeHeadSearch.IRecentViewedData[]
}) => {
  if (isEmpty(searchRecentViewData)) {
    return null
  }
  return (
    <div className="recently-viewed-wrapper min-[1310px]:flex-1 min-[1310px]:w-0">
      <h3 className="mb-3 font-bold">Recently Viewed</h3>
      <div className="flex flex-wrap">
        {searchRecentViewData?.map(item => (
          <div key={item.skuId} className="mr-[27px] mb-5">
            <a
              href={`${item.seoUrl}${
                item?.seoUrl?.includes('?') ? '&' : '?'
              }pdptypeAheadSearchInput=&tNtt=&tAt=item&tAv=${item.productName}`}
              data-name={item.productName}
              className="inline-block w-[72px] h-[72px] min-[1040px]:w-[90px] min-[1040px]:h-[90px] min-[1350px]:w-[104px] min-[1350px]:h-[104px] p-1 border border-textNormalColor"
            >
              <Image
                src={specifyImageSize(
                  `${IMAGE_PREFIX}MMGS7/${item.imageId}-00-120x120.jpg`,
                  100
                )}
                alt="search production"
                style={{ width: '100%', height: 'auto' }}
                width={100}
                height={100}
              />
            </a>
            <style global jsx>{`
              .recently-viewed-wrapper .mb-5 {
                margin-bottom: 1.25rem !important;
              }
            `}</style>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RecentlyViewed
