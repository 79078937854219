interface IphoneNumber {
  phoneNumber: string
  spaPhoneNumber: string
  bbbLinksHTML: string
  mobileConnectLinks: string
  loadClarip: (e: any) => void
}
import { MOBILE_CONNECT_LINKS_DEFAULT } from '@gc/common-lib/constants/global'
import { useMemo } from 'react'
function MobileFooter(props: IphoneNumber): React.ReactElement {
  const {
    phoneNumber,
    spaPhoneNumber,
    bbbLinksHTML,
    mobileConnectLinks,
    loadClarip
  } = props
  const newMobileConnectLinks =
    mobileConnectLinks === ''
      ? MOBILE_CONNECT_LINKS_DEFAULT
      : mobileConnectLinks
  const explicitImgWidth = useMemo(() => {
    const newLinkHTML = bbbLinksHTML.replace(
      /<img/g,
      '<img width="140px" height="42px" '
    )
    return newLinkHTML
  }, [bbbLinksHTML])

  return (
    <div className="mobile-footer element-hidden px-[10px] text-[#fff] text-center bg-[#424242]">
      <div className="contact-phone-list">
        <div className="contact-phone-wrapper float-left w-[48%] p-[1px] mx-0 my-[10px] bg-textNormalColor">
          <div className="contact-phone">
            {' '}
            <a href={`tel:${phoneNumber}`} className="block ui-link">
              {phoneNumber ? phoneNumber : '866-498-7882'}
            </a>{' '}
            <span>English</span>{' '}
          </div>
        </div>
        <div className="contact-phone-wrapper float-right w-[48%] p-[1px] mx-0 my-[10px] bg-textNormalColor spanish">
          <div className="contact-phone">
            {' '}
            <a href={`tel:${spaPhoneNumber}`} className="block ui-link">
              {spaPhoneNumber ? spaPhoneNumber : '877-687-5402'}
            </a>{' '}
            <span>Español</span>
          </div>
        </div>
        <div
          className="hidden float-left mx-0 w-full contact-phone-wrapper p-[1px] my-[10px] bg-textNormalColor"
          id="btnChatNowFooter"
        >
          <div className="contact-phone">
            {' '}
            <a
              href="/common/chatPopup.jsp?entryPointId=1001&amp;brandId=GCChat&amp;sourceId=GCChat&amp;liveChat=true"
              target="blank"
              className="block ui-link h-[43px]"
            >
              Chat Now
            </a>{' '}
          </div>
        </div>
      </div>

      <ul className="site-links  mb-0 float-left border border-[#4d4d4d] border-x-0">
        <li className="gift-card">
          {' '}
          <a href="/Gift-Card" className="float-left ui-link">
            Gift Card
          </a>{' '}
        </li>
        <li className="customer-service">
          {' '}
          <a href="/customer-service/" className="ui-link">
            Customer Service
          </a>{' '}
        </li>
        <li className="financing">
          {' '}
          <a href="/Special-Financing-Offers.gc" className="ui-link">
            Financing
          </a>{' '}
        </li>
        <li className="mobile-app">
          {' '}
          <a
            href="/pages/mobile-app"
            // downloadapp="//smart.link/5b5900bcdbd02"
            className="float-right ui-link"
          >
            Mobile App
          </a>{' '}
        </li>
      </ul>

      <div className="px-2 mobile-footer-section">
        <div className="float-left w-full leading-8 connect mb-[18px]">
          <ul className="social-icons mb-0 float-left w-[56%] max-w-[300px]">
            <li className="facebook">
              {' '}
              <a
                href="https://www.facebook.com/GuitarCenter"
                target="_blank"
                rel="noopener noreferrer"
                className="ui-link"
                aria-describedby="audioeye_new_window_message"
              >
                Facebook
              </a>{' '}
            </li>
            <li className="twitter">
              {' '}
              <a
                href="https://twitter.com/guitarcenter"
                target="_blank"
                rel="noopener noreferrer"
                className="ui-link"
                aria-describedby="audioeye_new_window_message"
              >
                Twitter
              </a>
            </li>
            <li className="youtube">
              {' '}
              <a
                href="https://www.youtube.com/guitarcenter"
                target="_blank"
                rel="noopener noreferrer"
                className="ui-link"
                aria-describedby="audioeye_new_window_message"
              >
                YouTube
              </a>{' '}
            </li>
            <li className="instagram">
              {' '}
              <a
                href="https://www.instagram.com/guitarcenter"
                target="_blank"
                rel="noopener noreferrer"
                className="ui-link"
                aria-describedby="audioeye_new_window_message"
              >
                Instagram
              </a>{' '}
            </li>
          </ul>
          <div className="feedback float-right w-[44%] text-right pt-[1px]">
            <a
              href="/customer-service/email-us?deptId=Feedback"
              id="feedback"
              className="feedback-link ui-link"
              rel="external"
            >
              Give Feedback
            </a>
          </div>
        </div>
        <div
          className="mobileConnectLinks"
          dangerouslySetInnerHTML={{ __html: newMobileConnectLinks }}
          onClick={loadClarip}
        />
        <div className="bbb-link-mobile mb-[10px]">
          <div
            className="footer-bbb-links-wrap"
            dangerouslySetInnerHTML={{ __html: explicitImgWidth }}
          />
        </div>
        <p className="copyright mb-[10px] text-[10px] leading-[1.3] pb-[10px]">
          Copyright © Guitar Center Inc.
        </p>
      </div>

      <style global jsx>
        {`
          .mobileConnectLinks {
            .legal {
              @media (max-width: 975.9px) {
                font-size: 10px;
                clear: both;
                display: table;
                margin: 0 auto 11px;
                width: 100%;

                & li {
                  display: table-cell;
                  vertical-align: middle;
                  font-family: Arial, Helvetica, 'Helvetica Neue', sans-serif;
                  line-height: 15px;
                  padding: 0 5px;
                }
                .ui-link {
                  line-height: 15.6px;
                }
              }

              @media (min-width: 568px) {
                font-size: 14px;
              }
              @media (min-width: 400px) {
                font-size: 12px;
              }
            }
            & a {
              color: #fff;
              text-decoration: none;
            }
          }

          .bbb-link-mobile .footer-bbb-links-wrap {
            display: block;
            .bbb-link {
              display: none;
            }
            .bbb-caru-link {
              margin: 0 auto;
              text-align: center;
              display: inline-block;
              & img {
                width: 140px !important;
              }
            }
          }
        `}
      </style>

      <style jsx>{`
        .mobile-footer {
          @media (max-width: 975.9px) {
            display: block;
            font-family: Arial, Helvetica, 'Helvetica Neue', sans-serif;
            text-align: center;
            & a {
              color: #fff;
              text-decoration: none;
            }
            .contact-phone a::before {
              display: none;
            }
            #feedback {
              font-family: var(--font-montserrat), Arial, Helvetica, sans-serif;
              font-size: 12px;
              font-weight: bold;
            }
          }
          .contact-phone-list {
            padding-left: 2%;
            padding-right: 2%;
          }

          .contact-phone-wrapper {
            background: linear-gradient(#666, var(--textNormalColor));
            border-radius: 4px;

            & span {
              padding-bottom: 7px;
              font-weight: bold;
              font-size: 12px;
              line-height: 1.3;
              vertical-align: top;
            }
          }

          #btnChatNowFooter a::before {
            position: relative;
            top: 5px;
            display: inline-block;
            width: 32px;
            height: 32px;
            margin: 0 10px 0 30px;
            background-image: url('https://static.guitarcenter.com/img/brand/mobile-gc/sprite_shared-ui_2x.webp?vId=ecmd-2021.8.1-gc-sam.0b');
            background-repeat: no-repeat;
            background-position: -215px -75px;
            background-size: 250px 172px;
          }

          .contact-phone {
            font-size: 20px;
            line-height: 20.8px;
            background: #424242;
            border-radius: 3px;

            & a.ui-link {
              display: block;
              padding: 10px;
              font-weight: bold;
              font-size: 20px;
              line-height: 20px;
              text-decoration: none;
            }
          }

          .site-links {
            float: left;
            width: 100%;
            padding: 12px 2%;
            font-size: 11px;
            line-height: 15.6px;

            & li {
              display: inline-block;
              font-family: var(--font-montserrat), Arial, Helvetica, sans-serif;
              float: left;
              width: 27%;
            }

            & li.gift-card,
            & li.mobile-app {
              width: 23%;
            }

            & a {
              position: relative;
              display: block;
              padding-top: 38px;
            }

            & a::before {
              position: absolute;
              top: 0;
              left: 50%;
              display: block;
              background-image: url('https://static.guitarcenter.com/img/brand/mobile-gc/sprite_shared-ui_2x.webp?vId=ecmd-2021.8.1-gc-sam.0b');
              background-repeat: no-repeat;
              background-size: 250px 172px;
              content: '';
            }

            .gift-card a::before {
              width: 54px;
              height: 32px;
              margin-left: -27px;
              background-position: 0 -72px;
            }

            .customer-service a::before {
              width: 31px;
              height: 32px;
              margin-left: -15px;
              background-position: -64px -40px;
            }

            .financing a::before {
              width: 54px;
              height: 32px;
              margin-left: -27px;
              background-position: -55px -72px;
            }

            .mobile-app a::before {
              left: 35%;
              width: 22px;
              height: 37px;
              background-image: url('https://static.guitarcenter.com/img/brand/mobile-gc/footerMobileAppIcon.png?vId=ecmd-2021.8.1-gc-sam.0b&cb=7');
              background-size: contain;
            }
          }

          .social-icons {
            & li {
              display: inline-block;
              float: left;
              width: 25%;
              font-size: 0;
            }

            & a {
              position: relative;
              display: block;
              width: 32px;
              height: 32px;
              color: var(--textNormalColor);
              font-size: 0;
              text-indent: -9999em;
              background-color: #fefefe;
              border-radius: 18px;
            }

            & a::after {
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 2;
              display: block;
              background-image: url('https://static.guitarcenter.com/img/brand/mobile-gc/sprite_shared-ui_2x.webp?vId=ecmd-2021.8.1-gc-sam.0b');
              background-repeat: no-repeat;
              background-size: 250px 172px;
              content: '';
            }

            .facebook a::after {
              width: 14px;
              height: 25px;
              margin: -12px 0 0 -7px;
              background-position: -100px -140px;
            }

            .twitter a::after {
              width: 21px;
              height: 19px;
              margin: -9px 0 0 -9px;
              background-position: -115px -140px;
            }

            .youtube a::after {
              width: 26px;
              height: 11px;
              margin: -5px 0 0 -13px;
              background-position: -137px -140px;
            }

            .instagram a::after {
              width: 18px;
              height: 18px;
              margin: -9px 0 0 -9px;
              background-position: -164px -140px;
            }
          }

          & a.feedback-link {
            font-size: 10px;
          }

          & a.feedback-link::before {
            position: relative;
            top: -1px;
            display: inline-block;
            width: 6px;
            height: 6px;
            margin-right: 2px;
            background-image: url('https://static.guitarcenter.com/img/brand/mobile-gc/sprite_shared-ui_2x.webp?vId=ecmd-2021.8.1-gc-sam.0b');
            background-repeat: no-repeat;
            background-position: -168px 0;
            background-size: 250px 172px;
            content: '';
          }

          .legal {
            & li {
              display: table-cell;
              padding: 0 5px;
              font-family: Arial, Helvetica, 'Helvetica Neue', sans-serif;
              line-height: 15px;
              vertical-align: middle;
            }

            .ui-link {
              line-height: 15.6px;
            }
          }

          .copyright::before {
            position: relative;
            top: 2px;
            display: inline-block;
            width: 40px;
            height: 21px;
            margin-right: 5px;
            background-image: url('https://static.guitarcenter.com/img/brand/mobile-gc/sprite_shared-ui_2x.webp?vId=ecmd-2021.8.1-gc-sam.0b');
            background-repeat: no-repeat;
            background-position: 0 -40px;
            background-size: 250px 172px;
            content: '';
          }
        }

        @media (min-width: 768px) {
          .legal li {
            width: 25%;
          }
          .contact-phone span {
            font-size: 20px;
          }
        }
      `}</style>
    </div>
  )
}

export default MobileFooter
