import type {
  InputProps as InputPropsType,
  InputRef as InputRefType,
  MappingAlgorithm
} from 'antd'
import {
  ConfigProvider,
  Input as AInput,
  InputNumber as AInputNumber
} from 'antd'
import type { PasswordProps } from 'antd/es/input/Password'
import type { ComponentToken } from 'antd/es/input/style'
import type { TextAreaProps, TextAreaRef } from 'antd/es/input/TextArea'
import type { AliasToken } from 'antd/es/theme/internal'
import { forwardRef } from 'react'

export interface IInputPropsType extends InputPropsType {
  inputConfigProvider?:
    | (Partial<ComponentToken> &
        Partial<AliasToken> & {
          algorithm?: boolean | MappingAlgorithm | MappingAlgorithm[]
        })
    | undefined
}

export interface ITextAreaPropsType extends TextAreaProps {
  textAreaConfigProvider?:
    | (Partial<ComponentToken> &
        Partial<AliasToken> & {
          algorithm?: boolean | MappingAlgorithm | MappingAlgorithm[]
        })
    | undefined
}

export interface IPasswordPropsType extends PasswordProps {
  passwordConfigProvider?:
    | (Partial<ComponentToken> &
        Partial<AliasToken> & {
          algorithm?: boolean | MappingAlgorithm | MappingAlgorithm[]
        })
    | undefined
}

const Input = forwardRef<InputRefType, IInputPropsType>((props, ref) => {
  const { inputConfigProvider, ...restProps } = props
  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            ...inputConfigProvider
          }
        }
      }}
    >
      <AInput ref={ref} {...restProps} />
    </ConfigProvider>
  )
})

export const TextArea = forwardRef<TextAreaRef, ITextAreaPropsType>(
  (props, ref) => {
    const { textAreaConfigProvider, ...restProps } = props
    return (
      <ConfigProvider
        theme={{
          components: {
            Input: {
              ...textAreaConfigProvider
            }
          }
        }}
      >
        <AInput.TextArea ref={ref} {...restProps} />
      </ConfigProvider>
    )
  }
)

export const Password = forwardRef<InputRefType, IPasswordPropsType>(
  (props, ref) => {
    const { passwordConfigProvider, ...restProps } = props
    return (
      <ConfigProvider
        theme={{
          components: {
            Input: {
              ...passwordConfigProvider
            }
          }
        }}
      >
        <AInput.Password ref={ref} {...restProps} />
      </ConfigProvider>
    )
  }
)

export default Input
export const InputNumber = AInputNumber
export type InputProps = InputPropsType
export type InputRef = InputRefType
