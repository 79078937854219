import { defaultPageSize } from './pageSize'

export const FACET_VALUES_LIMIT = 10

export const algoliaInitialConfigure: any = {
  facets: ['*'],
  maxValuesPerFacet: FACET_VALUES_LIMIT,
  page: 0,
  hitsPerPage: defaultPageSize
}

export const algoliaMainIndexName = process.env
  .NEXT_PUBLIC_ALGOLIA_MAIN_INDEX_NAME as string

export const algoliaSearchSuggestionIndexName = process.env
  .NEXT_PUBLIC_ALGOLIA_SEARCH_SUGGESTION_INDEX_NAME as string

export const storeAttributeName = 'stores'

export const ratingAttributeName = 'review.overallRating'

export const ALGOLIA_QUERY_LENGTH_LIMIT = 512
