import 'swiper/css'
// remember to import module styles separately when add a new module
import 'swiper/css/a11y'
import 'swiper/css/autoplay'
import 'swiper/css/grid'
import 'swiper/css/keyboard'
import 'swiper/css/mousewheel'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/thumbs'

import {
  A11y,
  Autoplay,
  FreeMode,
  Grid,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Thumbs
} from 'swiper/modules'
import type { SwiperProps, SwiperRef } from 'swiper/react'
import {
  Swiper as ReactSwiper,
  SwiperSlide as ReactSwiperSlide
} from 'swiper/react'

export const Swiper: React.FunctionComponent<
  React.RefAttributes<SwiperRef> & React.PropsWithChildren<SwiperProps>
> = (props: SwiperProps) => {
  return (
    <ReactSwiper
      a11y={{
        enabled: true
      }}
      modules={[
        Pagination,
        Autoplay,
        Mousewheel,
        Navigation,
        Thumbs,
        Keyboard,
        Scrollbar,
        Grid,
        A11y,
        FreeMode
      ]}
      {...props}
    />
  )
}
export const SwiperSlide = ReactSwiperSlide
